import { IconType } from 'types/icons';

export const IconPhone: IconType = ({ className }) => {
  return (
    <svg
      className={`${className || ''} svg-inline`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 10 11">
      <path d="M9.70703 7.32031L7.51953 6.38281C7.46094 6.36328 7.40234 6.34375 7.32422 6.34375C7.1875 6.34375 7.05078 6.42188 6.97266 6.51953L5.99609 7.71094C4.47266 6.98828 3.26172 5.77734 2.53906 4.25391L3.73047 3.27734C3.82812 3.19922 3.90625 3.0625 3.90625 2.90625C3.90625 2.84766 3.88672 2.78906 3.86719 2.73047L2.92969 0.542969C2.85156 0.367188 2.67578 0.25 2.48047 0.25C2.46094 0.25 2.42188 0.269531 2.38281 0.269531L0.351562 0.738281C0.136719 0.796875 0 0.972656 0 1.1875C0 6.20703 4.04297 10.25 9.0625 10.25C9.27734 10.25 9.45312 10.1133 9.51172 9.89844L9.98047 7.86719C9.98047 7.82812 9.98047 7.78906 9.98047 7.76953C9.98047 7.57422 9.86328 7.39844 9.70703 7.32031Z" />
    </svg>
  );
};
