import { IconType } from 'types/icons';

export const IconPassword: IconType = ({ className }) => {
  return (
    <svg
      className={`${className || ''} svg-inline`}
      viewBox="0 0 14 16"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M11.7188 8.375C12.4805 8.375 13.125 9.01953 13.125 9.78125V14.4688C13.125 15.2598 12.4805 15.875 11.7188 15.875H1.40625C0.615234 15.875 0 15.2598 0 14.4688V9.78125C0 9.01953 0.615234 8.375 1.40625 8.375H2.10938V5.38672C2.10938 2.92578 4.07227 0.904297 6.5332 0.875C8.99414 0.875 11.0156 2.86719 11.0156 5.32812V5.79688C11.0156 6.20703 10.6934 6.5 10.3125 6.5H9.375C8.96484 6.5 8.67188 6.20703 8.67188 5.79688V5.32812C8.67188 4.18555 7.70508 3.21875 6.5332 3.21875C5.36133 3.24805 4.45312 4.21484 4.45312 5.35742V8.375H11.7188Z" />
    </svg>
  );
};
