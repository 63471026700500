import { IconType } from 'types/icons';

export const IconEmail: IconType = ({ className }) => {
  return (
    <svg
      className={`${className || ''} svg-inline`}
      viewBox="0 0 15 12"
      xmlns="http://www.w3.org/2000/svg">
      <path d="M14.707 4.4707C14.8242 4.38281 15 4.4707 15 4.61719V10.5938C15 11.3848 14.3555 12 13.5938 12H1.40625C0.615234 12 0 11.3848 0 10.5938V4.61719C0 4.4707 0.146484 4.38281 0.263672 4.4707C0.9375 4.99805 1.78711 5.64258 4.77539 7.81055C5.39062 8.25 6.44531 9.2168 7.5 9.2168C8.52539 9.2168 9.60938 8.25 10.1953 7.81055C13.1836 5.64258 14.0332 4.99805 14.707 4.4707ZM7.5 8.25C6.79688 8.2793 5.83008 7.40039 5.33203 7.04883C1.43555 4.23633 1.14258 3.97266 0.263672 3.26953C0.0878906 3.15234 0 2.94727 0 2.71289V2.15625C0 1.39453 0.615234 0.75 1.40625 0.75H13.5938C14.3555 0.75 15 1.39453 15 2.15625V2.71289C15 2.94727 14.8828 3.15234 14.707 3.26953C13.8281 3.97266 13.5352 4.23633 9.63867 7.04883C9.14062 7.40039 8.17383 8.2793 7.5 8.25Z" />
    </svg>
  );
};
