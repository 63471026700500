import { IconType } from 'types/icons';

export const IconSearch: IconType = ({ className }) => {
  return (
    <svg
      className={`${className || ''} svg-inline`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 18 18">
      <path
        d="M17.8,15.6l-3.5-3.5c-0.2-0.1-0.4-0.2-0.6-0.2h-0.6c0.9-1.2,1.5-2.8,1.5-4.5c0-4-3.3-7.3-7.3-7.3
          C3.3,0,0,3.3,0,7.3c0,4,3.3,7.3,7.3,7.3c1.7,0,3.2-0.6,4.5-1.5v0.6c0,0.2,0.1,0.4,0.2,0.6l3.5,3.5c0.4,0.4,0.9,0.4,1.2,0l1-1
          C18.1,16.5,18.1,15.9,17.8,15.6z M7.3,11.8c-2.5,0-4.5-2-4.5-4.5c0-2.5,2-4.5,4.5-4.5c2.5,0,4.5,2,4.5,4.5
          C11.8,9.8,9.8,11.8,7.3,11.8z"
      />
    </svg>
  );
};
